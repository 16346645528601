import axios from "axios";
import { getBackendApiUrl } from "../utils/getBackendApiUrl";
import { goToSubscriptionProblemPage } from "../utils/goToSubscriptionProblemPage";
import { storeTenantInfo } from "../utils/storeTenantInfo";
import { getURLOfRedirectPages } from "../utils/getURLOfRedirectPages";

export default (isUserLoggedIn, tokenInfo, logout) => {
  try {
    const {
      accessDeniedPageUrl,
      subscriptionProblemPageUrl,
      manageSubscriptionPageUrl,
    } = getURLOfRedirectPages();

    const acceptLang = localStorage.i18nextLng ? localStorage.i18nextLng : "en";
    const backendApiUrl = getBackendApiUrl();
    if (process.env.NODE_ENV !== "production") {
      axios.defaults.withCredentials = true;
    }
    axios.defaults.baseURL = backendApiUrl;
    axios.defaults.headers.common = {
      "Accept-Language": acceptLang,
    };

    if (isUserLoggedIn) {
      axios.defaults.headers.common = {
        ...axios.defaults.headers.common,
        Authorization: `bearer ${tokenInfo?.token}`,
      };
      axios.interceptors.response.use(
        res => {
          storeTenantInfo(res);
          return res;
        },
        err => {
          if ([401].includes(err.response?.status)) {
            logout();
          } else if ([403].includes(err.response?.status)) {
            window.location.replace(accessDeniedPageUrl);
          } else if ([462].includes(err.response?.status)) {
            const message = err.response.data.message;
            goToSubscriptionProblemPage(subscriptionProblemPageUrl, message);
          } else if ([463].includes(err.response?.status)) {
            const isSuperAdmin = tokenInfo?.userRole === "Super Admin";
            if (isSuperAdmin) {
              window.location.replace(manageSubscriptionPageUrl);
            } else {
              const message = err.response.data.message;
              goToSubscriptionProblemPage(subscriptionProblemPageUrl, message);
            }
          }
          return Promise.reject(err);
        }
      );
    } else {
      axios.interceptors.response.use(
        res => {
          storeTenantInfo(res);
          return res;
        },
        err => {
          if ([462].includes(err.response?.status)) {
            const message = err.response.data.message;
            const emailNotConfirmed =
              err.response.data.targetObject?.emailNotConfirmed || false;
            goToSubscriptionProblemPage(
              subscriptionProblemPageUrl,
              message,
              emailNotConfirmed
            );
          }
          return Promise.reject(err);
        }
      );
    }
  } catch (err) {
    console.error(`Initialize axios failed | error: ${err}`);
  }
};
