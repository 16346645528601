import { useState, useEffect } from "react";
import axios from "axios";
import { useErrorHandler } from "react-error-boundary";
import { apiErrorHandler } from "../utils/apiErrorHandler";

const useAxiosFunction = (initResponse, updateProgressing) => {
  const [response, setResponse] = useState(initResponse);
  const [controller, setController] = useState();

  const handleError = useErrorHandler();

  const axiosFetch = async (
    configObj,
    responseHandler,
    apiErrorHandlerConfig
  ) => {
    const { method, url, data = null, requestConfig = {} } = configObj;
    let isReadableStream = false;
    let isArrayBufferValue = false;
    let confirmedFunction = null;
    let externalErrorStateHandler = null;
    if (apiErrorHandlerConfig) {
      isReadableStream = apiErrorHandlerConfig.isReadableStream || false;
      isArrayBufferValue = apiErrorHandlerConfig.isArrayBufferValue || false;
      confirmedFunction = apiErrorHandlerConfig.confirmedFunction || null;
      externalErrorStateHandler =
        apiErrorHandlerConfig.externalErrorStateHandler || null;
    }

    const ctrl = new AbortController();
    try {
      setController(ctrl);
      const convertedMethod = method.toLowerCase();
      const res = ["post"].includes(convertedMethod)
        ? await axios[method.toLowerCase()](url, data, {
            ...requestConfig,
            signal: ctrl.signal,
          })
        : await axios[method.toLowerCase()](url, {
            ...requestConfig,
            signal: ctrl.signal,
          });
      responseHandler && responseHandler(res.data);
    } catch (err) {
      if (!ctrl?.signal?.aborted) {
        updateProgressing && updateProgressing(false);
        apiErrorHandler(
          err,
          handleError,
          isArrayBufferValue,
          confirmedFunction,
          externalErrorStateHandler,
          isReadableStream
        );
      }
    }
  };

  useEffect(() => {
    return () => {
      if (controller) {
        const reason = new DOMException("cleaning up", "AbortError");
        controller.abort(reason);
      }
    };
  }, [controller]);

  return {
    response,
    setResponse,
    axiosFetch,
    controller,
  };
};

export default useAxiosFunction;
