import MainLogo from "../../UI/Logos/MainLogo";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import PagesNavbar from "./PagesNavbar/PagesNavbar";

function LayoutContainer({ children }) {
  return (
    <div>
      <header className="relative z-1 flex items-center justify-between px-7 shadow-md">
        <Link to="/">
          <MainLogo />
        </Link>
        <PagesNavbar />
      </header>
      <SimpleBar className="h-[calc(100vh-64px)] relative bg-layout dark:bg-dark-12 w-full overflow-x-hidden" forceVisible="y" autoHide={false}>
        <article className="w-full h-[inherit]">{children}</article>
      </SimpleBar>
    </div>
  );
}

export default LayoutContainer;
