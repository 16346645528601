import i18next from "i18next";
import showWarningMessage from "./alerts/showWarningMessage";
import showInfoMessage from "./alerts/showInfoMessage";
import showClosableToastAlert from "./alerts/showClosableToastAlert";

async function getReadableStreamError(data) {
  const decoderStream = new TextDecoderStream();
  const reader = data.pipeThrough(decoderStream).getReader();
  let errorMessage = "";

  try {
    let done = false;
    while (!done) {
      const { value, done: isDone } = await reader.read();
      if (value) {
        errorMessage += value;
      }
      done = isDone;
    }
    const parsedErrorMessage = JSON.parse(errorMessage);
    return parsedErrorMessage?.message || "Failed to parse error message.";
  } catch (err) {
    return "Failed to process stream.";
  }
}

function parseArrayBuffer(buffer) {
  try {
    const parsedMessage = JSON.parse(Buffer.from(buffer).toString("utf8"));
    return parsedMessage?.message || "Failed to parse array buffer.";
  } catch (err) {
    return "Failed to parse array buffer.";
  }
}

function extractErrorMessage(response) {
  if (response?.data?.message) return response.data.message;
  if (response?.data?.title) return response.data.title;
  if (response?.data) return response.data;
  return "Bad request";
}

async function getErrorMessage(error, isArrayBufferValue, isReadableStream) {
  if (isReadableStream) {
    return await getReadableStreamError(error.response.data);
  }

  if (isArrayBufferValue && error.response.data.byteLength > 0) {
    return parseArrayBuffer(error.response.data);
  }

  return extractErrorMessage(error.response);
}

/**
 * @function apiErrorHandler
 * Handles API errors by determining the appropriate response based on the error type and status code.
 * - Displays different messages for network errors, server errors, and specific HTTP status codes.
 * - Calls external error handlers if provided.
 *
 * @param {Object} error - The error object received from the API call.
 * @param {Function} handleError - A callback function to handle errors not related to API responses.
 * @param {boolean} isArrayBufferValue - Indicates if the response is an ArrayBuffer.
 * @param {Function} confirmedFunction - A callback function to execute after displaying an error message.
 * @param {Function} [externalErrorStateHandler] - An optional external handler for managing error states.
 * @param {boolean} isReadableStream - Indicates if the response is a ReadableStream.
 * @returns {void}
 */
export async function apiErrorHandler(
  error,
  handleError,
  isArrayBufferValue,
  confirmedFunction,
  externalErrorStateHandler,
  isReadableStream
) {
  const t = i18next.t;
  try {
    if (error.response) {
      if (error.response.status === 500) {
        showClosableToastAlert("error", t("we_are_sorry_something_went_wrong"));
      } else {
        if ([401, 403, 462, 463].includes(error.response.status)) {
          return;
        }

        let errMessage = await getErrorMessage(
          error,
          isArrayBufferValue,
          isReadableStream
        );

        if (error.response.status === 404 && !error.response?.data)
          errMessage = "API Not Found";

        externalErrorStateHandler
          ? externalErrorStateHandler(errMessage)
          : showWarningMessage(null, errMessage, confirmedFunction);
      }
    } else if (error.request) {
      let errorTitle = "Oops!";
      let errorMessage = error;
      if (error.request.status === 0) {
        errorTitle = t("network_error");
        errorMessage = t("please_check_network_connection_and_try_again");
      }
      externalErrorStateHandler
        ? externalErrorStateHandler(errorMessage)
        : showInfoMessage(errorTitle, errorMessage, confirmedFunction);
    } else {
      handleError(error);
    }
  } catch (err) {
    handleError(err);
  }
}
