/** @module GlobalConstants */

/** @constant {number}
 *  @description API error message timeout
 */
export const ERROR_MSG_TIMEOUT = 10000;

/** @constant {Object}
 *  @description URL validation pattern
 *  ^: asserts the start of the string.
    (https?:\/\/)?: matches the optional "http://" or "https://".
    ([\da-z.-]+): matches the domain name, which can include alphanumeric characters, dots, and hyphens.
    \.([a-z.]{2,6}): matches the top-level domain (TLD), which consists of a dot followed by 2 to 6 letters.
*/
export const URL_PATTERN = {
  value: /^(https?:\/\/)?([a-zA-Z0-9][\da-z.-]+[a-zA-Z0-9])\.([a-z.]{2,6})$/,
  message: "url_pattern_msg",
};

/** @constant {Object}
 *  @description Password validation pattern
 */
export const PASSWORD_PATTERN = {
  value:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9])([A-Za-z\d]|[^a-zA-Z0-9]){6,15}$/,
  message: "password_pattern_msg",
};

/** @constant {Object}
 *  @description Data collection password validation pattern
 */
export const DATA_COLLECTION_PASSWORD_PATTERN = {
  value: /^\d{6}$/,
  message: "must_be_6_digit_number",
};

/** @constant {Object}
 * @description Email validation pattern
 */
export const EMAIL_PATTERN = {
  value:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  message: "must_be_a_valid_email",
};

/** @constant {Object}
 * @description Validation code pattern
 */
export const VALIDATION_CODE_PATTERN = {
  value: /[0-9]{6}/,
  message: "verification_code_validation_msg",
};

/** @constant {string[]}
 * @default ["Country","Governorate","District","SubDistrict","Street"]
 */
export const LOCATION_LEVELS = [
  "Country",
  "Governorate",
  "District",
  "SubDistrict",
  "Street",
];

/** @constant {string[]}
 */
export const LOCATION_LEVEL_USE_OPTIONS = [
  "Country",
  "Governorate",
  "District",
];

/** @constant {boolean[]}
 */
export const LOCATION_LEVEL_NEED_FILTERING = [false, false, true, false, false];

/** @constant {Object[]}
 */
export const PROJECT_PRIORITIES = [
  { label: "Very Important", color: "border-warning" },
  { label: "Important", color: "border-[#f39c12]" },
  { label: "Normal", color: "border-normal" },
  { label: "All", color: "border-black" },
];

/** @constant {string[]}
 */
export const FULL_DATE_FORMATS = ["yyyy-MM-dd", "MM-dd-yyyy"];

/** @constant {string}
 */
export const YEAR_DATE_FORMAT = "yyyy";

/** @constant {string}
 */
export const MONTH_YEAR_DATE_FORMAT = "yyyy-MM";

/** @constant {string[]}
 */
export const SHORT_DATE_FORMATS = [MONTH_YEAR_DATE_FORMAT, YEAR_DATE_FORMAT];

/** @constant {string[]}
 * @description Submission (interview)  location source
 * @default [
  "GPS",
  "Manual Entry Not Checked",
  "Manual Entry Checked",
  "Invalid Manual Entry",
  ]
 */
export const LOCATION_SOURCE = [
  "GPS",
  "Manual Entry Not Checked",
  "Manual Entry Checked",
  "Invalid Manual Entry",
];

/** @constant {string[]}
 * @description Submission (interview)  retrieve status
 */
export const RETRIEVE_STATUS = [
  "Normal",
  "Force Retrieve",
  // "Task Expired",
  // "Project Closed",
];

/** @constant {string[]}
 *  @description Target page name - used in favorite
 */
export const TARGET_PAGE = [
  "Dashboard",
  "ProjectDashboard",
  "ActivityDashboard",
  "Project",
  "ProjectUsers",
  "ActivityUsers",
  "AuditLog",
  "RolesManagement",
  "UsersManagement",
  "AllViews",
  "SummarySurvey",
  "SubmissionsSurvey",
  "ViewsSurvey",
  "TasksSurvey",
  "DataEntrySurvey",
  "DataExportSurvey",
  "DesignSurvey",
  "SystemSetting",
  "LocationsSetting",
  "LangsSetting",
  "PCodeSetting",
  "SectorsSetting",
  "CurrenciesSetting",
  "CriteriaSetting",
  "SecuritySetting",
  "ContactsSetting",
  "ContactGroupsSetting",
  "CampaignsManagement",
  "EntityTypesManagement",
  "DataAnalysisUsingAISurvey",
];

/** @constant {Object}
 */
export const VERIFICATION_TYPE = {
  AccountActivation: 0,
  ConfirmEmail: 1,
  ResetPassword: 2,
  ConfirmMobileNumber: 3,
  TwoFactorAuthentication: 4,
};

/** @constant {Object}
 */
export const SEND_VERIFICATION_CODE_BY = {
  Email: 0,
  SMS: 1,
};

/** @constant {Object}
 * @default { latitude: 35.94, longitude: 15.09 }
 */
export const DEFAULT_MAP_POSITION = {
  latitude: 35.94,
  longitude: 15.09,
};

/** @constant {string[]}
 * @description operations for FilterBuilder devextreme component
 */
export const STRING_FILTER_OPERATIONS = [
  "contains",
  "notcontains",
  "startswith",
  "endswith",
  "<>",
];

/** @constant {string[]}
 * @description operations for FilterBuilder devextreme component
 */
export const NUMERIC_FILTER_OPERATIONS = [">", ">=", "<", "<=", "<>"];

/** @constant {string[]}
 * @description operations for FilterBuilder devextreme component
 */
export const SUPPORTED_GROUP_OPERATIONS = ["and", "or"];
